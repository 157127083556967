var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "fullscreen": ""
    },
    on: {
      "open": _vm.fetchData
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "rank-wrapper"
  }, [_c('div', {
    staticClass: "filter-bar"
  }, [_c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "align": "right",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.onDateRangeChange
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c('el-select', {
    staticClass: "select",
    attrs: {
      "filterable": "",
      "clearable": "",
      "placeholder": "项目"
    },
    on: {
      "change": _vm.fetchList
    },
    model: {
      value: _vm.queryData.estateId,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "estateId", $$v);
      },
      expression: "queryData.estateId"
    }
  }, _vm._l(_vm.estateList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1)], 1), _c('ul', {
    staticClass: "rank-table-wrap"
  }, [_c('li', [_c('h3', [_vm._v("报备排行榜")]), _c('el-table', {
    attrs: {
      "data": _vm.reportRankList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "index",
      "label": "排名",
      "width": "50",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var $index = _ref.$index;
        return [_c('span', [_vm._v(_vm._s($index + 1))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "报备数",
      "width": "100",
      "align": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('span', [_vm._v(_vm._s(row.value) + "条")])];
      }
    }])
  })], 1)], 1), _c('li', [_c('h3', [_vm._v("带看排行榜")]), _c('el-table', {
    attrs: {
      "data": _vm.visitRankList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "index",
      "label": "排名",
      "width": "50",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var $index = _ref3.$index;
        return [_c('span', [_vm._v(_vm._s($index + 1))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "带看数",
      "width": "100",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('span', [_vm._v(_vm._s(row.value) + "条")])];
      }
    }])
  })], 1)], 1), _c('li', [_c('h3', [_vm._v("客户判定统计")]), _c('el-table', {
    attrs: {
      "data": _vm.customerDetermineList,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "类型",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "数量",
      "width": "100",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('span', [_vm._v(_vm._s(row.value) + "条")])];
      }
    }])
  })], 1)], 1), _c('li', [_c('h3', [_vm._v("人员分布")]), _c('el-table', {
    attrs: {
      "data": _vm.mechanismUserFollowStatistics,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "渠道人员",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "拜访",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('span', [_vm._v(_vm._s(row.visitCount || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "培训",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c('span', [_vm._v(_vm._s(row.cultivate || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "其他",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c('span', [_vm._v(_vm._s(row.other || "-"))])];
      }
    }])
  })], 1)], 1), _c('li', [_c('h3', [_vm._v("渠道分布")]), _c('el-table', {
    attrs: {
      "data": _vm.mechanismFollowStatistics,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "渠道名称",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "拜访",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c('span', [_vm._v(_vm._s(row.visitCount || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "培训",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c('span', [_vm._v(_vm._s(row.cultivate || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "其他",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c('span', [_vm._v(_vm._s(row.other || "-"))])];
      }
    }])
  })], 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }