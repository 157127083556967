<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" fullscreen @open="fetchData">
    <div class="rank-wrapper">
      <div class="filter-bar">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="onDateRangeChange"
        >
        </el-date-picker>
        <el-select
          class="select"
          v-model="queryData.estateId"
          filterable
          clearable
          placeholder="项目"
          @change="fetchList"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in estateList"
            :key="index"
          ></el-option>
        </el-select>
      </div>
      <ul class="rank-table-wrap">
        <li>
          <h3>报备排行榜</h3>
          <el-table :data="reportRankList" border>
            <el-table-column
              type="index"
              label="排名"
              width="50"
              align="center"
            >
              <template slot-scope="{ $index }">
                <span>{{ $index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="渠道" prop="name"> </el-table-column>
            <el-table-column label="报备数" width="100" align="right">
              <template slot-scope="{ row }">
                <span>{{ row.value }}条</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          <h3>带看排行榜</h3>
          <el-table :data="visitRankList" border>
            <el-table-column
              type="index"
              label="排名"
              width="50"
              align="center"
            >
              <template slot-scope="{ $index }">
                <span>{{ $index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="渠道" prop="name"> </el-table-column>
            <el-table-column label="带看数" width="100" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.value }}条</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          <h3>客户判定统计</h3>
          <el-table :data="customerDetermineList" border>
            <el-table-column label="类型" prop="name"> </el-table-column>
            <el-table-column label="数量" width="100" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.value }}条</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          <h3>人员分布</h3>
          <el-table :data="mechanismUserFollowStatistics" border>
            <el-table-column label="渠道人员" prop="name"></el-table-column>
            <el-table-column label="拜访" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.visitCount || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="培训" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.cultivate || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="其他" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.other || "-" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li>
          <h3>渠道分布</h3>
          <el-table :data="mechanismFollowStatistics" border>
            <el-table-column label="渠道名称" prop="name"></el-table-column>
            <el-table-column label="拜访" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.visitCount || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="培训" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.cultivate || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="其他" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.other || "-" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import {
  getReportListCondition,
  getReportRankListByChannel,
  getCustomerDetermineStatistics,
  getMechanismFollowStatistics,
  getMechanismUserFollowStatistics
} from "@/api/distribution";
export default {
  data() {
    return {
      queryData: {
        startTime: "",
        estateId: "",
        size: 10,
        reportState: 1 // 1 新增报备 2 已带看
      },
      dateRange: [],
      estateList: [],
      reportRankList: [],
      visitRankList: [],
      customerDetermineList: [],
      mechanismFollowStatistics: [],
      mechanismUserFollowStatistics: []
    };
  },
  methods: {
    onDateRangeChange(e) {
      const [startTime, endTime] = e || [];
      Object.assign(this.queryData, { startTime, endTime });
      this.fetchList();
    },
    fetchData() {
      this.getReportListCondition();
      this.fetchList();
    },
    fetchList() {
      this.getReportRankList();
      this.getVisitRankList();
      this.getCustomerDetermineStatistics();
      this.getMechanismFollowStatistics();
      this.getMechanismUserFollowStatistics();
    },
    // 客户判定统计
    async getCustomerDetermineStatistics() {
      const res = await getCustomerDetermineStatistics(this.queryData);
      this.customerDetermineList = res || [];
    },
    // 报备排行榜
    async getReportRankList() {
      const res = await getReportRankListByChannel(this.queryData);
      this.reportRankList = res || [];
    },
    // 带看排行榜
    async getVisitRankList() {
      const res = await getReportRankListByChannel({
        ...this.queryData,
        reportState: 2
      });
      this.visitRankList = res || [];
    },
    // 渠道跟进统计-渠道
    async getMechanismFollowStatistics() {
      const res = await getMechanismFollowStatistics({
        dateStart: this.queryData.startTime,
        dateEnd: this.queryData.endTime
      });
      this.mechanismFollowStatistics = res || [];
    },
    // 渠道跟进统计-用户
    async getMechanismUserFollowStatistics() {
      const res = await getMechanismUserFollowStatistics({
        dateStart: this.queryData.startTime,
        dateEnd: this.queryData.endTime
      });
      this.mechanismUserFollowStatistics = res || [];
    },
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition({
        platformIds: [this.$store.state.platformInfo.platformId]
      });
      this.estateList = res?.estates || [];
    }
  }
};
</script>

<style scoped lang="scss">
.filter-bar {
  & > * {
    margin-right: 10px;
  }
}
.rank-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.filter-bar {
  padding: 20px;
}
.rank-table-wrap {
  padding: 0 20px 20px 20px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  li {
    h3 {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
    }
  }
}
::v-deep {
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }
  .el-table__header-wrapper {
    thead {
      tr {
        th {
          .cell {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
