<template>
  <div class="report base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">报备台账</h3>
      </div>
    </div>
    <div class="filter">
      <el-input
        placeholder="报备人姓名或客户姓名、手机号"
        v-model="queryData.keyword"
        class="f-search-bar"
        style="width: 320px;margin-right: 10px;"
      >
        <el-button slot="append" @click="getReportList">搜索</el-button>
      </el-input>
      <el-select
        v-if="isPlatform"
        class="select"
        v-model="queryData.estates"
        placeholder="报备项目"
        multiple
        filterable
        collapse-tags
        @change="getReportList"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="item in conditionMap.estates"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-else
        class="select"
        v-model="queryData.sourceNos"
        placeholder="报备来源"
        multiple
        filterable
        collapse-tags
        @change="getReportList"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="item in conditionMap.sources"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="queryData.organs"
        placeholder="报备渠道"
        multiple
        filterable
        collapse-tags
        @change="getReportList"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="(item, index) in conditionMap.organs"
          :key="index"
        ></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="queryData.reportStates"
        placeholder="报备状态"
        multiple
        collapse-tags
        @change="getReportList"
      >
        <el-option
          :value="item.id"
          :label="item.name"
          v-for="item in reportStates"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="queryData.reportTime"
        type="daterange"
        align="right"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="handleReportTimeChange"
      >
      </el-date-picker>
      <!-- <el-button class="ml20" type="primary" @click="rankVisible = true"
        >统计</el-button
      > -->
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="报备单号">
        <template v-slot="{ row }">
          <el-button type="text" @click="handleRowClick(row)">{{
            row.reportCode
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="报备来源"
        :prop="`${isPlatform ? 'estateName' : 'sourceName'}`"
      ></el-table-column>
      <el-table-column label="客户姓名" prop="customerName"></el-table-column>
      <el-table-column label="客户电话">
        <template v-slot="{ row }">
          {{ row.customerTelephone | formatPhone }}
        </template>
      </el-table-column>
      <el-table-column label="报备人" prop="reportUserName"></el-table-column>
      <el-table-column label="报备渠道" prop="company"></el-table-column>
      <el-table-column label="报备时间" prop="inDate"></el-table-column>
      <el-table-column label="到访时间" prop="visitTime">
        <template v-slot="{ row }">
          <span>{{ row.visitTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="{ row }">
          <span
            :class="[
              statusMap[row.reportState] && statusMap[row.reportState].color
            ]"
            >{{ row.reportStateStr }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="带看码" prop="">
        <template v-slot="{ row }">
          <el-button
            class="visit-code-btn"
            type="text"
            v-if="row.visitQrCode || row.otherQrCode"
            >带看码
            <el-image
              class="image"
              :src="
                row.visitQrCode
                  ? `${QRCODE_GENERATE_URL}${row.visitQrCode}`
                  : row.otherQrCode
              "
              :preview-src-list="[
                row.visitQrCode
                  ? `${QRCODE_GENERATE_URL}${row.visitQrCode}`
                  : row.otherQrCode
              ]"
            >
            </el-image>
          </el-button>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getReportList"
    ></f-pagination>
    <report-detail
      :visible.sync="reportDetailVisible"
      :report-id="activeReportId"
    ></report-detail>
    <rank-dialog :visible.sync="rankVisible"></rank-dialog>
  </div>
</template>

<script>
import { getReportList, getReportListCondition } from "@/api/distribution";
import { QRCODE_GENERATE_URL } from "@/config";
import ReportDetail from "../report/_components/reportDetail";
import RankDialog from "./_components/rankDialog.vue";
export default {
  components: {
    ReportDetail,
    RankDialog
  },
  data() {
    return {
      QRCODE_GENERATE_URL,
      queryData: {
        estates: [], //报备楼盘id
        keyword: "",
        organs: [], //报备渠道
        pageNum: 1,
        pageSize: 10,
        platformIds: [], //聚合平台Id
        reportTime: [], //报备时间 【前端用】
        reportEndTime: "", //报备时间
        reportStartTime: "",
        reportStates: [], //报备状态
        sourceNos: [] // 报备来源
      },
      statusMap: {
        0: {
          color: "orange"
        },
        1: {
          color: ""
        },
        2: {
          color: "green"
        },
        3: {
          color: "red"
        },
        8: {
          color: "gray"
        }
      },
      // 筛选条件
      conditionMap: {
        estates: [],
        organs: []
      },
      reportStates: [
        {
          id: 0,
          name: "待审"
        },
        {
          id: 1,
          name: "通过"
        },
        {
          id: 2,
          name: "已带看"
        },
        {
          id: 3,
          name: "不通过"
        }
      ],
      list: [{}],
      total: 0,
      isPlatform: false, // 是否是聚合平台中的报备带看
      activeReportId: null, //当前操作报备的id
      reportDetailVisible: false, // 控制详情抽屉显示
      rankVisible: false
    };
  },
  created() {
    this.initData();
    this.getReportListCondition();
    this.getReportList();
  },
  methods: {
    initData() {
      // 如果是聚合平台
      if (this.$route.path === "/distribution/appManage/report") {
        this.isPlatform = true;
      }
      // 如果是聚合平台的报备
      if (this.isPlatform) {
        this.queryData.platformIds = [
          this.$store.state.platformInfo.platformId
        ];
        // 不然就是楼盘项目的报备
      } else {
        this.queryData.estates = [this.$store.state.estateInfo.estateId];
      }
    },
    handleRowClick(row) {
      this.reportDetailVisible = true;
      this.activeReportId = row.id;
    },
    // 报备时间选择
    handleReportTimeChange(val) {
      val = val || [];
      this.queryData.reportStartTime = val[0];
      this.queryData.reportEndTime = val[1];
      this.getReportList();
    },
    // 筛选条件
    async getReportListCondition() {
      const res = await getReportListCondition(this.queryData);
      this.conditionMap = res || {};
    },
    // 获取报备列表
    async getReportList() {
      const res = await getReportList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.report {
  .filter {
    padding-top: 20px;
    .select {
      width: 150px;
      margin-right: 10px;
      ::v-deep {
        .el-select__tags {
          flex-wrap: nowrap;
          .el-tag {
            display: flex;
            align-items: center;
            .el-select__tags-text {
              display: block;
              max-width: 60px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .body {
    margin-top: 10px;
    .gray {
      color: #999 !important;
    }
    .visit-code-btn {
      position: relative;
      ::v-deep {
        .el-image {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          &__inner {
            opacity: 0;
          }
        }
      }
    }
  }
  ::v-deep {
    .el-drawer__wrapper {
      left: auto;
      .el-drawer__container {
        width: 450px;
      }
    }
  }
}
</style>
