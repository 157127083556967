var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report base-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px",
      "margin-right": "10px"
    },
    attrs: {
      "placeholder": "报备人姓名或客户姓名、手机号"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.getReportList
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _vm.isPlatform ? _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "报备项目",
      "multiple": "",
      "filterable": "",
      "collapse-tags": ""
    },
    on: {
      "change": _vm.getReportList
    },
    model: {
      value: _vm.queryData.estates,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "estates", $$v);
      },
      expression: "queryData.estates"
    }
  }, _vm._l(_vm.conditionMap.estates, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1) : _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "报备来源",
      "multiple": "",
      "filterable": "",
      "collapse-tags": ""
    },
    on: {
      "change": _vm.getReportList
    },
    model: {
      value: _vm.queryData.sourceNos,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "sourceNos", $$v);
      },
      expression: "queryData.sourceNos"
    }
  }, _vm._l(_vm.conditionMap.sources, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1), _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "报备渠道",
      "multiple": "",
      "filterable": "",
      "collapse-tags": ""
    },
    on: {
      "change": _vm.getReportList
    },
    model: {
      value: _vm.queryData.organs,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "organs", $$v);
      },
      expression: "queryData.organs"
    }
  }, _vm._l(_vm.conditionMap.organs, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1), _c('el-select', {
    staticClass: "select",
    attrs: {
      "placeholder": "报备状态",
      "multiple": "",
      "collapse-tags": ""
    },
    on: {
      "change": _vm.getReportList
    },
    model: {
      value: _vm.queryData.reportStates,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "reportStates", $$v);
      },
      expression: "queryData.reportStates"
    }
  }, _vm._l(_vm.reportStates, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    });
  }), 1), _c('el-date-picker', {
    attrs: {
      "type": "daterange",
      "align": "right",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.handleReportTimeChange
    },
    model: {
      value: _vm.queryData.reportTime,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "reportTime", $$v);
      },
      expression: "queryData.reportTime"
    }
  })], 1), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "报备单号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRowClick(row);
            }
          }
        }, [_vm._v(_vm._s(row.reportCode))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "报备来源",
      "prop": "".concat(_vm.isPlatform ? 'estateName' : 'sourceName')
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "客户姓名",
      "prop": "customerName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "客户电话"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm._f("formatPhone")(row.customerTelephone)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "报备人",
      "prop": "reportUserName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "报备渠道",
      "prop": "company"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "报备时间",
      "prop": "inDate"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "到访时间",
      "prop": "visitTime"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('span', [_vm._v(_vm._s(row.visitTime || "-"))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('span', {
          class: [_vm.statusMap[row.reportState] && _vm.statusMap[row.reportState].color]
        }, [_vm._v(_vm._s(row.reportStateStr))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "带看码",
      "prop": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.visitQrCode || row.otherQrCode ? _c('el-button', {
          staticClass: "visit-code-btn",
          attrs: {
            "type": "text"
          }
        }, [_vm._v("带看码 "), _c('el-image', {
          staticClass: "image",
          attrs: {
            "src": row.visitQrCode ? "".concat(_vm.QRCODE_GENERATE_URL).concat(row.visitQrCode) : row.otherQrCode,
            "preview-src-list": [row.visitQrCode ? "".concat(_vm.QRCODE_GENERATE_URL).concat(row.visitQrCode) : row.otherQrCode]
          }
        })], 1) : _c('span', [_vm._v("-")])];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getReportList
    }
  }), _c('report-detail', {
    attrs: {
      "visible": _vm.reportDetailVisible,
      "report-id": _vm.activeReportId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reportDetailVisible = $event;
      }
    }
  }), _c('rank-dialog', {
    attrs: {
      "visible": _vm.rankVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.rankVisible = $event;
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("报备台账")])])]);

}]

export { render, staticRenderFns }